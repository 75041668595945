@import url('https://rsms.me/inter/inter.css');


html {
  background: #F6F7F9;
  font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

body {
	font-size: 16px;
	line-height: 1.5;
	color: #000;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}


a {
	text-decoration: none;
}

select:not([multiple]):not([size]) {
	background-image: none;
}


.loader-2 {
  display: block;
	height: 24px;
	width: 24px;
  animation: loader-2-1 3s linear infinite;
}

@keyframes loader-2-1 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

.loader-2 span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 24px;
	width: 24px;
	clip: rect(12px, 24px, 24px, 0);
  animation: loader-2-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@keyframes loader-2-2 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

.loader-2 span::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 24px;
	width: 24px;
	border: 3px solid #fff;
	border-radius: 50%;
}


.ReactModal__Content {
  transition: transform 0.2s ease-in, opacity 0.1s ease-in;
  transform: scale(0.8) translateY(50px);
  opacity: 0;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transform: scale(1) translateY(0);
}

.ReactModal__Content--before-close {
  transform: scale(0.8) translateY(50px);
  opacity: 0 !important;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}
